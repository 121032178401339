import {
	FETCH_USER_ACTION_START,
	FETCH_USER_ACTION_OK,
	FETCH_USER_ACTION_ERR,
	SAVE_USER_ACTION_OK,
	CREATE_USER_ACTION_START,
	CREATE_USER_ACTION_OK,
	CREATE_USER_ACTION_ERR,
} from '../actions/users';
import {
	COLLECTION_REMOVE_MEMBER_ACTION_OK
} from '../actions/collections';
import {
	expandDynamoKeys
} from '../shared/constants';

const defaultUsersState = {

	user: {
		pk: '',
		sk1: '',
		fullName: '',
		email: '',
		role: '',
		avator: '',
		slug: '',
		createdTs: '',
		lastLoginTs: '',
		sub: '',
		collections: [],
		preferences: {},
		favorites: [],
	},
	isLoading: false,
	modalLoading: false,
	errorMsg: '',
	modalError: '',
};

export default function UsersReducer(state = defaultUsersState, action) {

	switch (action.type) {

		case FETCH_USER_ACTION_START:
			return {
				...state,
				isLoading: true,
				modalLoading: false,
				errorMsg: '',
				modalError: '',
				user: {
					...defaultUsersState.user,
					userid: action.userid,
				}
			};

		case FETCH_USER_ACTION_OK:
			console.log('FETCH_USER_ACTION_OK', { action });
			const app = expandDynamoKeys(action.app);
			return {
				...state,
				isLoading: false,
				modalLoading: false,
				errorMsg: '',
				modalError: '',
				user: {
					...state.user,
					...action.user,
				}
	  		};

		case FETCH_USER_ACTION_ERR:
			return {
				...state,
				isLoading: false,
				modalLoading: false,
				errorMsg: action.message,
				modalError: '',
			};

		case CREATE_USER_ACTION_START:
			return {
				...state,
				isLoading: true,
				modalLoading: false,
				errorMsg: '',
				modalError: '',
			};

		case CREATE_USER_ACTION_OK:
			console.log('CREATE_USER_ACTION_OK', { action });
			return {
				...state,
				isLoading: false,
				modalLoading: false,
				errorMsg: '',
				modalError: '',
	  		};

		case CREATE_USER_ACTION_ERR:
			return {
				...state,
				isLoading: false,
				modalLoading: false,
				errorMsg: action.error,
				modalError: '',
			};

		case SAVE_USER_ACTION_OK:
			console.log('SAVE_USER_ACTION_OK', { action });
			return {
				...state,
				isLoading: false,
				modalLoading: false,
				errorMsg: '',
				modalError: '',
				user: {
					...state.user,
					...action.user,
				}
	  		};

		case COLLECTION_REMOVE_MEMBER_ACTION_OK:
			return {
				...state,
				user: {
					collections: state.user.collections.filter(c => c.split('#')[0] !== action.collectionid),
				}
			}
		default:
			return state;
	}
}
