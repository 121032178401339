import {
	FETCH_COLLECTION_ACTION_START,
	FETCH_COLLECTION_ACTION_OK,
	FETCH_COLLECTION_ACTION_ERR,
	CREATE_COLLECTION_ACTION_START,
	CREATE_COLLECTION_ACTION_OK,
	CREATE_COLLECTION_ACTION_ERR,
	SAVE_COLLECTION_ACTION_START,
	SAVE_COLLECTION_ACTION_OK,
	SAVE_COLLECTION_ACTION_ERR,
	COLLECTION_ADD_MEMBER_ACTION_OK,
	COLLECTION_REMOVE_MEMBER_ACTION_OK,
	COLLECTION_ADD_SUBMISSION_ACTION_OK,
	COLLECTION_REMOVE_SUBMISSION_ACTION_OK,
	DELETE_SUBMISSION_ACTION_OK,
} from '../actions/collections';

import {
	expandDynamoKeys
} from '../shared/constants';

const defaultCollectionsState = {
	collection: {
		pk: '',
		sk1: '',
		firstName: '',
		lastName: '',
		fullName: '',
		description: '',
		createdTs: '',
		startDate: '',
		endDate: '',
		userid: '',
		members: [],
		submissions: [],
	},
	isLoading: false,
	modalLoading: false,
	errorMsg: '',
	modalError: '',
};

export default function CollectionsReducer(state = defaultCollectionsState, action) {

	switch (action.type) {

		case FETCH_COLLECTION_ACTION_START:
			return {
				...state,
				isLoading: true,
				modalLoading: false,
				errorMsg: '',
				modalError: '',
				collection: {
					...defaultCollectionsState.collection,
					collectionid: action.collectionid,
				}
			};

		case FETCH_COLLECTION_ACTION_OK:
			console.log('FETCH_COLLECTION_ACTION_OK', { action });
			const col = expandDynamoKeys(action.collection);
			return {
				...state,
				isLoading: false,
				modalLoading: false,
				errorMsg: '',
				modalError: '',
				collection: {
					...state.collection,
					...col,
				}
	  		};

		case FETCH_COLLECTION_ACTION_ERR:
			return {
				...state,
				isLoading: false,
				modalLoading: false,
				errorMsg: action.message,
				modalError: '',
			};

		case CREATE_COLLECTION_ACTION_START:
			return {
				...state,
				isLoading: true,
				errorMsg: ''
			}

		case CREATE_COLLECTION_ACTION_OK:
			return {
				...state,
				isLoading: false,
				errorMsg: '',
				collection: action.collection,
			}

		case CREATE_COLLECTION_ACTION_ERR:
			return {
				...state,
				isLoading: false,
				modalLoading: false,
				errorMsg: action.message,
				modalError: '',
			};

			case SAVE_COLLECTION_ACTION_START:
				return {
					...state,
					isLoading: true,
					errorMsg: ''
				}
	
			case SAVE_COLLECTION_ACTION_OK:
				return {
					...state,
					isLoading: false,
					errorMsg: '',
					collection: action.collection,
				}
	
			case SAVE_COLLECTION_ACTION_ERR:
				return {
					...state,
					isLoading: false,
					modalLoading: false,
					errorMsg: action.message,
					modalError: '',
				};

		case COLLECTION_ADD_MEMBER_ACTION_OK:
			return {
				...state,
				isLoading: false,
				collection: {
					...state.collection,
					members: [action.memberid, ...state.collection.members]
				}
			};

		case COLLECTION_REMOVE_MEMBER_ACTION_OK:
			return {
				...state,
				isLoading: false,
				collection: {
					...state.collection,
					members: state.collection.members.filter((m) => m !== action.memberid)
				}
			};

		case COLLECTION_ADD_SUBMISSION_ACTION_OK:
			return {
				...state,
				isLoading: false,
				collection: {
					...state.collection,
					submissions: [
						...state.collection.submissions,
						action.submissionid
					]
				}
			}

		case COLLECTION_REMOVE_SUBMISSION_ACTION_OK:
			console.log('REDUCER COLLECTION_REMOVE_SUBMISSION_ACTION_OK', action);
			var [innerSubId, innerSubName] = action.submissionid.split('#');
			return {
				...state,
				isLoading: false,
				collection: {
					...state.collection,
					submissions: [
						...state.collection.submissions.filter((s => s.submissionid !== innerSubId)),
					]
				}
			}
		
		default:
			return state;
	}
}
