import React, { useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux';
import { HashRouter, Route, Switch } from 'react-router-dom'
import './scss/style.scss'
import '@aws-amplify/ui-react/styles.css'
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components'

import {
  Authenticator,
  Heading,
  Text,
  Button,
  View,
  useTheme,
} from '@aws-amplify/ui-react'
import Amplify, { Auth, Hub } from 'aws-amplify'
import awsconfig from './aws-exports'

Amplify.configure(awsconfig)

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const Login = React.lazy(() => import('./views/Login'))

// wrap this in aws auth
const App = (props) => {

  const [authState, setAuthState] = useState()
  const [user, setUser] = useState({ attributes: {}, username: '' })

  const { username } = user

  const loginAction = async (values, successCb, errorCb) => {
    let { username, password } = values

    try {
      const authResp = await Auth.signIn({ username, password })
      setUser(authResp)
      successCb(authResp)
      setAuthState('signedin');
    } catch (error) {
      errorCb(error)
    }
  }

  const validateAccountAction = async (values, successCb, errorCb) => {
    try {
      let authData = await Auth.confirmSignUp(values.username, values.code)
      authData = await Auth.signIn({ username: values.username, password: values.password })
      // console.log('Hey! Confirm Signup login success', authData);
      successCb(authData)
    } catch (error) {
      errorCb(error)
    }
  }

  const logoutAction = async () => {
    console.log('Hey! Logout')
    try {
      setUser({ attributes: {} })
      setAuthState('')
      await Auth.signOut()
    } catch (e) {
      console.log('Signout error', e)
    }
  }

  const forgotPasswordAction = async (username, successCb, errorCb) => {
    try {
      const authResp = await Auth.forgotPassword(username)
      successCb(authResp)
    } catch (error) {
      errorCb(error)
    }
  }

  const setNewPasswordAction = async (username, password, successCb, errorCb) => {
    try {
      console.log('Setting new password for ' + username);
      const authResp = await Auth.completeNewPassword(username, password)
      setUser(authResp)
      successCb(authResp)
      setAuthState('signedin')
    } catch (error) {
      errorCb(error)
    }
  }

  const resetPasswordAction = async (values, successCb, errorCb) => {
    try {
      const authResp = await Auth.forgotPasswordSubmit(username, values.code, values.password)
      setUser(authResp)
      successCb(authResp)
    } catch (error) {
      errorCb(error)
    }
  }

  const resendConfirmationAction = async (username, successCb, errorCb) => {
    try {
      const resendResp = await Auth.resendSignUp(username)
      console.log('Resend resp', { resendResp })
      successCb(
        'A new auth code has been sent via ' +
          resendResp.CodeDeliveryDetails.DeliveryMedium.toLowerCase() +
          '.',
      )
    } catch (error) {
      console.log('Resend auth error', { error })
      errorCb('An error occurred, please try again')
    }
  }

  const handleAuthChange = useCallback(async function (payload) {
    switch (payload.event) {
      case 'signOut':
        console.log('Cleaning up session')
        sessionStorage.removeItem('userid')
        window.localStorage.clear()
        console.log('Logged out')
        window.location = '/#/login'
        break
      default:
        console.log('Auth change event ' + payload.event)
        break
    }
  }, [])

  useEffect(() => {
    Hub.listen('auth', (data) => {
      const { payload } = data
      handleAuthChange(payload)
    })
  }, [handleAuthChange])

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((resp) => {
        console.log('userinfo inner resp', resp);
        console.log('User is authenticated')
        setUser(resp)
        setAuthState(resp.username ? AuthState.SignedIn : '')
      })
      .catch((err) => {
        console.log('User is not authenticated')
        setAuthState('foo')
        setUser({ attributes: {} })
      })
  }, [setUser, setAuthState])
  const formFields = {
    confirmVerifyUser: {
      confirmation_code: {
        label: 'New Label',
        placeholder: 'Enter your Confirmation Code:',
        isRequired: false,
      },
    },
  };
  
  const components = {
    SignIn: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Ancestry VMP
          </Heading>
        );
      }
    }, 

    VerifyUser: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
      Footer() {
        return <Text>Footer Information</Text>;
      },
    },
  
    ConfirmVerifyUser: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
      Footer() {
        return <Text>Footer Information</Text>;
      },
    },
  };
  
  return (
    <Authenticator
      formFields={formFields}
      components={components}
      hideSignUp={true}
    >
      {({ signOut, user }) => (
    <HashRouter>
      <React.Suspense fallback={loading}>
        <Switch>
          <Route path="/">
            <DefaultLayout
              {...props}
              user={user}
              authState={authState}
              logoutAction={signOut}
            />
          </Route>
          <Route path="/login">

          </Route>
        </Switch>
      </React.Suspense>
    </HashRouter>
      )} 
    </Authenticator>
  )
}
export default App;


