import { API } from 'aws-amplify';
import { decodeCriteria } from '../shared/constants';

const dayjs = require('dayjs');

export const UPDATE_SESSION_OK = 'UPDATE_SESSION_OK';
export const LOAD_ENVIRONMENT_OK = 'LOAD_ENVIRONMENT_OK';

export function updateSessionAction(values) {
	console.log('UPDATE SESSION: ', values);
	return (dispatch) => {
		dispatch({type: 'set', ...values});
	}
}

export function loadEnvironment(successCb, errorCb) { 
	console.log('LOAD ENVIRONMENT ACTION');
	return async (dispatch) => {
		console.log('LOAD ENVIRONMENT THUNK');
		try {
			let endpoint = '/api/admin/me';

			console.log('Grabbing preferences ... ' + endpoint);
			let prefs = false; // sessionStorage.getItem('prefs');
			if (!prefs) {
				console.log('GRABBING PREFS && COLLECTIONS');
				let prefsResp = await API.get('AncestryApi', endpoint);
				console.log('Found prefs' , { prefsResp });
				delete prefsResp.status;
				sessionStorage.setItem('prefs', JSON.stringify(prefsResp));
				prefs = prefsResp;
			} else {
				prefs = JSON.parse(prefs);
			}
			
			let tags = sessionStorage.getItem('tags');
			if (!tags) {
				console.log('GRABBING TAGS');
				let endpoint2 = '/api/admin/tags';
				const tagsResp = await API.get('AncestryApi', endpoint2);
				console.log('Found tags ' , { tags: tagsResp.tags});
				tags = tagsResp.tags;
				sessionStorage.setItem('tags', JSON.stringify(tags));
			} else {
				tags = JSON.parse(tags);
			}

			console.log('LOAD ENV', {tags, prefs})

			dispatch({type: LOAD_ENVIRONMENT_OK, tags, collections: prefs.collections });
			successCb && successCb();
			return true;
		} catch (error) {
			console.log('LOAD ENV error:', { error });
			errorCb && errorCb(error.message);
			return false;
		}
	}
}

export const LOGIN_ACTION_START = 'LOGIN_ACTION_START';
export const LOGIN_ACTION_OK = 'LOGIN_ACTION_OK';
export const LOGIN_ACTION_ERR = 'LOGIN_ACTION_ERR';

const loginActionStart = () => { return { type: LOGIN_ACTION_START }};
const loginActionOk = (token) => { return { type: LOGIN_ACTION_OK, token }};
const loginActionErr = (error) => { return { type: LOGIN_ACTION_ERR, error}};

export function loginAction(username, password, successCb, errorCb) {

	console.log('loginAction START');

	return async (dispatch) => {

		dispatch(loginActionStart());
		try {
			let endpoint = '/auth/login';
			console.log('LOGIN ' + endpoint);
			const payload = await API.post('AncestryApi', endpoint, { data: { username, password }});
			console.log('LOGIN payload ' , { payload });
			dispatch(loginActionOk(payload.token));
			successCb && successCb();
			return true;
		} catch (error) {
			console.log('LOGIN error:', { error });
			errorCb && errorCb(error.message);
			dispatch(loginActionErr(error.message));
			return false;
		}
	}
}
