import { combineReducers } from 'redux';
import campaigns from './campaigns';
import collections from './collections';
import options from './options';
import session from './session';
import submissions from './submissions';
import users from './users';

export default combineReducers({
    campaigns,
    collections,
    options,
    session,
    submissions,
    users,
});
