import React from 'react';
import { Spinner } from 'reactstrap';

export const LoadingWidget = () => {
	return (
			<div className="animated fadeIn pt-1 text-center loading-overlay loading-widget"><i className="fa fa-spinner fa-pulse fa-3x fa-fw" /></div>
	)
};

export const SOCKET_URL = 'http://camunda.billfusion.com:3001';

export const expandDynamoKeys = (obj) => {
	let out = {};

	for (var key in obj) {
		// console.log('expandDynamo: checking ' + key + ' val ' + obj[key]);
		out[key] = obj[key];
		var namematches = key.match(/^(\w+)id$/);
		if (namematches && obj[key].toString().includes('#')) {
			var matches = obj[key].match(/^(.*)\#(.*)$/);
			// console.log('expandDynamo: matches ', { matches, namematches });
			if (matches) {
				// console.log('expandDynamo: setting ' + namematches[1] + ' to ' + matches[2]);
				out[namematches[1]] = matches[2];
			}
		}
	}
	// console.log('expandDynamo: returning', { out });
	return out;
};

export const encodeCriteria = (criteria) => {
	console.log('encodeCriteria', {criteria});
	const pairs = Object.keys(criteria).map((k) => {
		return (Array.isArray(criteria[k]))
			? k + ':' + criteria[k].join(',')
			: k + ':' + criteria[k];
	});
	console.log('encodeCriteria', {pairs});
	const joined = pairs.join(';');
	return encodeURI(joined);
}

export const decodeCriteria = (critString) => {
	const pairs = decodeURI(critString).split(';');
	let criteria = {};
	pairs.map((p) => {
		if (!p || p === undefined) return null;
		const [k, v] = p.split(':');
		criteria[k] = (v !== undefined && v.indexOf(',') > -1)
			? v.split(',')
			: v;
		return null;
	});
	return criteria;
}

export const formatMoney = (input) => {
	input = parseFloat(input);
	if (typeof input !== 'number') return '$0.00';
	const neg = (input < 0);
	if (neg) {
		const out = input * -1;
		return '- $' + out.toLocaleString('en-US', {
			minimumFractionDigits: 2
		});
	} else {
		return '$' + input.toLocaleString('en-US', {
			minimumFractionDigits: 2
		});
	}
}

export const UsStates = {
	AL: 'Alabama',
	AK: 'Alaska',
	AZ: 'Arizona',
	AR: 'Arkansas',
	CA: 'California',
	CO: 'Colorado',
	CT: 'Connecticut',
	DE: 'Delaware',
	DC: 'District of Columbia',
	FL: 'Florida',
	GA: 'Georgia',
	HI: 'Hawaii',
	ID: 'Idaho',
	IL: 'Illinois',
	IN: 'Indiana',
	IA: 'Iowa',
	KS: 'Kansas',
	KY: 'Kentucky',
	LA: 'Louisiana',
	ME: 'Maine',
	MD: 'Maryland',
	MA: 'Massachusetts',
	MI: 'Michigan',
	MN: 'Minnesota',
	MS: 'Mississippi',
	MO: 'Missouri',
	MT: 'Montana',
	NE: 'Nebraska',
	NV: 'Nevada',
	NH: 'New Hampshire',
	NJ: 'New Jersey',
	NM: 'New Mexico',
	NY: 'New York',
	NC: 'North Carolina',
	ND: 'North Dakota',
	OH: 'Ohio',
	OK: 'Oklahoma',
	OR: 'Oregon',
	PA: 'Pennsylvania',
	RI: 'Rhode Island',
	SC: 'South Carolina',
	SD: 'South Dakota',
	TN: 'Tennessee',
	TX: 'Texas',
	UT: 'Utah',
	VT: 'Vermont',
	VA: 'Virginia',
	WA: 'Washington',
	WV: 'West Virginia',
	WI: 'Wisconsin',
	WY: 'Wyoming',
};

export const thisYr = new Date().getFullYear();
export const thisMo = new Date().getMonth();