import {
	FETCH_CAMPAIGN_ACTION_START,
	FETCH_CAMPAIGN_ACTION_OK,
	FETCH_CAMPAIGN_ACTION_ERR,
	SAVE_CAMPAIGN_ACTION_START,
	SAVE_CAMPAIGN_ACTION_OK,
	SAVE_CAMPAIGN_ACTION_ERR,
	CREATE_CAMPAIGN_ACTION_START,
	CREATE_CAMPAIGN_ACTION_OK,
	CREATE_CAMPAIGN_ACTION_ERR,
	ADD_CAMPAIGN_ASSET_ACTION_START,
	ADD_CAMPAIGN_ASSET_ACTION_OK,
	ADD_CAMPAIGN_ASSET_ACTION_ERR,
} from '../actions/campaigns';

import {
	expandDynamoKeys
} from '../shared/constants';

const defaultCampaignsState = {
	campaigns: {
		encoded: null, // the raw searchparams
		criteria: {},  // the converted criteria
		results: {}
	},
	campaign: {
		pk: '',
		sk1: '',
		fullName: '',
		title: '',
		intro: '',
		memberName: '',
		memberVideo: '',
		memberImage: '',
		thoughtStarters: [],
		confirmation: '',
		startDate: '',
		endDate: '',
		assets: {},
	},
	isLoading: false,
	modalLoading: false,
	errorMsg: '',
	modalError: '',
};

export default function CampaignsReducer(state = defaultCampaignsState, action) {

	switch (action.type) {

		case FETCH_CAMPAIGN_ACTION_START:
			return {
				...state,
				isLoading: true,
				modalLoading: false,
				errorMsg: '',
				modalError: '',
				campaign: {
					...defaultCampaignsState.campaign,
					campaignid: action.campaignid,
				}
			};

		case FETCH_CAMPAIGN_ACTION_OK:
			console.log('FETCH_CAMPAIGN_ACTION_OK', { action });
			const app = expandDynamoKeys(action.app);
			return {
				...state,
				isLoading: false,
				modalLoading: false,
				errorMsg: '',
				modalError: '',
				campaign: {
					...state.campaign,
					...action.campaign,
				}
	  		};

		case FETCH_CAMPAIGN_ACTION_ERR:
			return {
				...state,
				isLoading: false,
				modalLoading: false,
				errorMsg: action.message,
				modalError: '',
			};

		case SAVE_CAMPAIGN_ACTION_START:
			return {
				...state,
				isLoading: true,
				modalLoading: false,
				errorMsg: '',
				modalError: '',
			};

		case SAVE_CAMPAIGN_ACTION_OK:
				console.log('SAVE_CAMPAIGN_ACTION_OK', { action });
				return {
					...state,
					isLoading: false,
					modalLoading: false,
					errorMsg: '',
					modalError: '',
					campaign: {
						...state.campaign,
						...action.campaign,
					}
				  };
		
		case SAVE_CAMPAIGN_ACTION_ERR:
			return {
				...state,
				isLoading: false,
				modalLoading: false,
				errorMsg: action.message,
				modalError: '',
			};

			case CREATE_CAMPAIGN_ACTION_START:
				return {
					...state,
					isLoading: true,
					modalLoading: false,
					errorMsg: '',
					modalError: '',
					campaign: {
						...defaultCampaignsState.campaign,
						campaignid: action.campaignid,
					}
				};
	
			case CREATE_CAMPAIGN_ACTION_OK:
				return {
					...state,
					isLoading: false,
					modalLoading: false,
					errorMsg: '',
					modalError: '',
					campaign: {
						...state.campaign,
						...action.campaign,
					}
				  };
	
			case CREATE_CAMPAIGN_ACTION_ERR:
				return {
					...state,
					isLoading: false,
					modalLoading: false,
					errorMsg: action.message,
					modalError: '',
				};

				case ADD_CAMPAIGN_ASSET_ACTION_START:
					console.log('ADD_CAMPAIGN_ASSET START', action);
					return {
						...state,
						isLoading: true,
						modalLoading: false,
						errorMsg: '',
						modalError: '',
					};
		
				case ADD_CAMPAIGN_ASSET_ACTION_OK:
					// returns action.assetType and action.asset
					let updatedAssets = (state.campaign.assets)
						? { ...state.campaign.assets }
						: { banner: {}, headshot: {}, video: {} };
					updatedAssets[action.assetType] = action.asset;
					console.log('ADD_CAMPAIGN_ASSET OK', {action, updatedAssets});
					return {
						...state,
						isLoading: false,
						modalLoading: false,
						errorMsg: '',
						modalError: '',
						campaign: {
							...state.campaign,
							assets: updatedAssets,
						}
					  };
		
				case ADD_CAMPAIGN_ASSET_ACTION_ERR:
					return {
						...state,
						isLoading: false,
						modalLoading: false,
						errorMsg: action.message,
						modalError: '',
					};				
		default:
			return state;
	}
}
