import { API } from 'aws-amplify';
import { decodeCriteria } from '../shared/constants';
import StandardTemplate from '../shared/StandardTemplate';

const dayjs = require('dayjs');

export const FETCH_CAMPAIGN_ACTION_START = 'FETCH_CAMPAIGN_ACTION_START';
export const FETCH_CAMPAIGN_ACTION_OK = 'FETCH_CAMPAIGN_ACTION_OK';
export const FETCH_CAMPAIGN_ACTION_ERR = 'FETCH_CAMPAIGN_ACTION_ERR';

const fetchCampaignStart = (campaignid) => { return { type: FETCH_CAMPAIGN_ACTION_START, campaignid}};
const fetchCampaignOk = (campaign) => { return { type: FETCH_CAMPAIGN_ACTION_OK, campaign }};
const fetchCampaignErr = (error) => { return { type: FETCH_CAMPAIGN_ACTION_ERR, error }};

export function fetchCampaignAction(campaignid, successCb, errorCb) {

	console.log('fetchCampaignsAction START');

	return async (dispatch) => {

		console.log('fetchCampaignAction: START', campaignid);
	
		dispatch(fetchCampaignStart(campaignid));

		try {
			let endpoint = '/api/admin/campaign/' + campaignid;
			console.log('QUERY Querying ... ' + endpoint);
				
			const payload = await API.get('AncestryApi', endpoint);
			console.log('QUERY Found campaign', { payload });
			dispatch(fetchCampaignOk(payload.campaign));
			successCb && successCb(payload.campaign);
			return true;
		} catch (error) {
			console.log('QUERY campaign error:', { error });
			dispatch(fetchCampaignErr(error.message));
			errorCb && errorCb(error.message);
			return false;
		}

	}
}

export const SAVE_CAMPAIGN_ACTION_START = 'SAVE_CAMPAIGN_ACTION_START';
export const SAVE_CAMPAIGN_ACTION_OK = 'SAVE_CAMPAIGN_ACTION_OK';
export const SAVE_CAMPAIGN_ACTION_ERR = 'SAVE_CAMPAIGN_ACTION_ERR';

const saveCampaignStart = (campaignid) => { return { type: SAVE_CAMPAIGN_ACTION_START, campaignid}};
const saveCampaignOk = (campaign) => { return { type: SAVE_CAMPAIGN_ACTION_OK, campaign }};
const saveCampaignErr = (error) => { return { type: SAVE_CAMPAIGN_ACTION_ERR, error }};

export function saveCampaignAction(campaignid, values, successCb, errorCb) {

	console.log('saveCampaignsAction START');

	return async (dispatch) => {

		console.log('saveCampaignAction: START', campaignid);
	
		dispatch(saveCampaignStart(campaignid));

		try {
			let endpoint = '/api/admin/campaign/' + campaignid;
			console.log('POST ... ' + endpoint);
				
			const resp = await API.post('AncestryApi', endpoint, { body: values });
			dispatch(saveCampaignOk(resp.campaign));
			successCb && successCb();
			return true;
		} catch (error) {
			console.log('POST campaign error:', { error });
			dispatch(saveCampaignErr(error.message));
			errorCb && errorCb(error.message);
			return false;
		}

	}
}

export const CREATE_CAMPAIGN_ACTION_START = 'CREATE_CAMPAIGN_ACTION_START';
export const CREATE_CAMPAIGN_ACTION_OK = 'CREATE_CAMPAIGN_ACTION_OK';
export const CREATE_CAMPAIGN_ACTION_ERR = 'CREATE_CAMPAIGN_ACTION_ERR';

const createCampaignStart = () => { return { type: CREATE_CAMPAIGN_ACTION_START}};
const createCampaignOk = (campaign) => { return { type: CREATE_CAMPAIGN_ACTION_OK, campaign }};
const createCampaignErr = (error) => { return { type: CREATE_CAMPAIGN_ACTION_ERR, error }};

export function createCampaignAction(values, successCb, errorCb) {

	console.log('createCampaignsAction START');

	return async (dispatch) => {

		console.log('createCampaignAction: START', values);
	
		dispatch(createCampaignStart());

		try {
			let endpoint = '/api/admin/campaign';
			console.log('POST ... ' + endpoint);
				
			const resp = await API.post('AncestryApi', endpoint, { body: values });
			dispatch(createCampaignOk(resp.campaign));
			successCb && successCb(resp.campaign.pk);
			return true;
		} catch (error) {
			console.log('POST campaign error:', { error });
			dispatch(createCampaignErr(error.message));
			errorCb && errorCb(error.message);
			return false;
		}

	}
}

export const ADD_CAMPAIGN_ASSET_ACTION_START = 'ADD_CAMPAIGN_ASSET_ACTION_START';
export const ADD_CAMPAIGN_ASSET_ACTION_OK = 'ADD_CAMPAIGN_ASSET_ACTION_OK';
export const ADD_CAMPAIGN_ASSET_ACTION_ERR = 'ADD_CAMPAIGN_ASSET_ACTION_ERR';

const addCampaignAssetStart = () => { return { type: ADD_CAMPAIGN_ASSET_ACTION_START}};
const addCampaignAssetOk = (assetType, asset) => { return { type: ADD_CAMPAIGN_ASSET_ACTION_OK, assetType, asset}};
const addCampaignAssetErr = (error) => { return { type: ADD_CAMPAIGN_ASSET_ACTION_ERR, error }};

export function addCampaignAssetAction(campaignid, values, successCb, errorCb) {

	console.log('addCampaignAssetsAction START');

	return async (dispatch) => {

		console.log('addCampaignAssetAction: START', values);
	
		dispatch(addCampaignAssetStart(values));

		try {
			let endpoint = `/api/admin/campaign/${campaignid}/asset`;
			console.log('POST ... ' + endpoint);
				
			const resp = await API.post('AncestryApi', endpoint, { body: values });
			dispatch(addCampaignAssetOk(values.assetType, resp.asset));
			successCb && successCb();
			return true;
		} catch (error) {
			console.log('POST campaign error:', { error });
			dispatch(addCampaignAssetErr(error.message));
			errorCb && errorCb(error.message);
			return false;
		}

	}
}

export const PREVIEW_CAMPAIGN_ACTION_START = 'PREVIEW_CAMPAIGN_ACTION_START';
export const PREVIEW_CAMPAIGN_ACTION_OK = 'PREVIEW_CAMPAIGN_ACTION_OK';
export const PREVIEW_CAMPAIGN_ACTION_ERR = 'PREVIEW_CAMPAIGN_ACTION_ERR';

const previewCampaignStart = (campaignid) => { return { type: PREVIEW_CAMPAIGN_ACTION_START, campaignid}};
const previewCampaignOk = (campaign) => { return { type: PREVIEW_CAMPAIGN_ACTION_OK, campaign }};
const previewCampaignErr = (error) => { return { type: PREVIEW_CAMPAIGN_ACTION_ERR, error }};

export function previewCampaignAction(campaignid, values, successCb, errorCb) {

	console.log('previewCampaignsAction START');

	return async (dispatch) => {

		console.log('previewCampaignAction: START', campaignid);
	
		dispatch(previewCampaignStart(campaignid));

		try {
			let endpoint = `/api/admin/campaign/${campaignid}/preview`;
			console.log('POST ... ' + endpoint);
				
			const resp = await API.post('AncestryApi', endpoint, { body: values });
			dispatch(previewCampaignOk(resp.slug));
			successCb && successCb(resp.slug);
			return true;
		} catch (error) {
			console.log('POST campaign error:', { error });
			dispatch(previewCampaignErr(error.message));
			errorCb && errorCb(error.message);
			return false;
		}

	}
}
