import {
	SEARCH_SUBMISSIONS_ACTION_START,
	SEARCH_SUBMISSIONS_ACTION_OK,
	SEARCH_SUBMISSIONS_ACTION_ERR,
	FETCH_SUBMISSION_ACTION_START,
	FETCH_SUBMISSION_ACTION_OK,
	FETCH_SUBMISSION_ACTION_ERR,
	ADD_SUBMISSION_TO_COLLECTION_ACTION_START,
	ADD_SUBMISSION_TO_COLLECTION_ACTION_OK,
	ADD_SUBMISSION_TO_COLLECTION_ACTION_ERR,
	REMOVE_SUBMISSION_FROM_COLLECTION_ACTION_START,
	REMOVE_SUBMISSION_FROM_COLLECTION_ACTION_OK,
	REMOVE_SUBMISSION_FROM_COLLECTION_ACTION_ERR,
	ADD_SUBMISSION_NOTE_ACTION_START,
	ADD_SUBMISSION_NOTE_ACTION_OK,
	ADD_SUBMISSION_NOTE_ACTION_ERR,
	DELETE_SUBMISSION_ACTION_START,
	DELETE_SUBMISSION_ACTION_OK,
	DELETE_SUBMISSION_ACTION_ERR,
	SAVE_TRANSCRIPTION_ACTION_START,
	SAVE_TRANSCRIPTION_ACTION_OK,
	SAVE_TRANSCRIPTION_ACTION_ERR,
	SAVE_TAGS_ACTION_START,
	SAVE_TAGS_ACTION_OK,
	SAVE_TAGS_ACTION_ERR,
	PARTNER_SUBMISSIONS_ACTION_START,
	PARTNER_SUBMISSIONS_ACTION_OK,
	PARTNER_SUBMISSIONS_ACTION_ERR,
	ADD_RATING_ACTION_START,
	ADD_RATING_ACTION_OK,
	ADD_RATING_ACTION_ERR,
	REMOVE_RATING_ACTION_START,
	REMOVE_RATING_ACTION_OK,
	REMOVE_RATING_ACTION_ERR
} from '../actions/submissions';

import {
	COLLECTION_ADD_SUBMISSION_ACTION_OK
} from '../actions/collections';

import {
	expandDynamoKeys
} from '../shared/constants';

const defaultSubmissionsState = {
	submissions: {
		encoded: null, // the raw searchparams
		criteria: {},  // the converted criteria
		results: [],
	},
	submission: {
		pk: '',
		sk1: '',
		fullName: '',
		description: '',
		createdTs: '',
		startDate: '',
		endDate: '',
		ratings: [],
		numRating: 0,
		highRating: 0,
		aveRating: 0,
		image: {},
		video: {},
		notes: [],
		collections: [],
		tags: [],
	},
	isLoading: false,
	modalLoading: false,
	errorMsg: '',
	modalError: '',
};

const testSubmissionsState = {
	submissions: {
		encoded: null, // the raw searchparams
		criteria: {},  // the converted criteria
		results: [], 
	},
	submission: {
		"pk": "SUB-3781",
		"sk1": "SUB-3781",
		"imageid": "8116",
		"imageWidth": "0",
		"highRating": "1",
		"videoSize": "2345593",
		"email": "jct2trkg@aol.com",
		"country": "",
		"state": "MO",
		"city": "SAINT JOSEPH",
		"ratings": [
		 {
		  "rating": "1",
		  "fullName": "Elizabeth Asdorian",
		  "userid": "7",
		  "createdTs": "2021-02-11 18:12:02"
		 }
		],
		"imageUri": "ancestrys3/3781-161e50d868e5f0c16dc64652d4bdc593-1612973507-1612973522.jpg",
		"createdTs": "2021-02-10 08:16:02",
		"notes": [
		 {
		  "note": "Elizabeth Asdorian rated this submission 1 star",
		  "fullName": "System ",
		  "createdTs": "2021-02-11 18:12:02",
		  "userid": "1"
		 },
		 {
		  "note": "ISO maternal grandmother's Native America side.",
		  "fullName": "Elizabeth Asdorian",
		  "createdTs": "2021-02-11 18:12:04",
		  "userid": "7"
		 },
		 {
		  "note": "Indigenous",
		  "fullName": "Elizabeth Asdorian",
		  "createdTs": "2021-02-23 20:46:37",
		  "userid": "7"
		 }
		],
		"tags": [
			{slug: "bravery", label: "Bravery"},
			{slug: "wwii", label: "WWII"},
			{slug: "immigration", label: "Immigration"},
		],
		"videoUri": "ancestrys3/161e50d868e5f0c16dc64652d4bdc593-1612973507.mov",
		"videoMime": "mov",
		"isFavorite": "0",
		"postal": "64505",
		"phone": "816-752-3556",
		"imageStatus": "processed",
		"age": "65+",
		"imageMime": "jpg",
		"experience": "1 or less",
		"theme": "other",
		"updatedTs": "2021-02-10 11:02:47",
		"vimeoid": "510747495",
		"source": "experience",
		"imageSize": "10570",
		"videoid": "8115",
		"fullName": "Pam Tapee",
		"gender": "Female",
		"byDay": "2021-02-10",
		"entityStatus": "active",
		"campaignid": "50016",
		"aveRating": "1.00",
		"numRating": "1",
		"imageHeight": "0",
		"videoStatus": "raw",
		"submissionid": "3781",
		"collections": [],
	   },
	isLoading: false,
	modalLoading: false,
	errorMsg: '',
	modalError: '',
};

export default function submissionsReducer(state = defaultSubmissionsState, action) {

	let expanded = {};

	switch (action.type) {

		case SEARCH_SUBMISSIONS_ACTION_START:
		case PARTNER_SUBMISSIONS_ACTION_START:
			return {
				...state,
				isLoading: true,
				modalLoading: false,
				errorMsg: '',
				modalError: '',
				submissions: {
					encoded: action.searchCriteria,
					criteria: {},
					results: [],
				}
			};

		case SEARCH_SUBMISSIONS_ACTION_OK:
		case PARTNER_SUBMISSIONS_ACTION_OK:
			return {
				...state,
				isLoading: false,
				modalLoading: false,
				errorMsg: '',
				modalError: '',
				submissions: {
					...state.submissions,
					results: action.results || [],
					total: action.total || 0,
					criteria: action.criteria || {},
				}
			};

		case SEARCH_SUBMISSIONS_ACTION_ERR:
		case PARTNER_SUBMISSIONS_ACTION_ERR:
			return {
				...state,
				isLoading: false,
				modalLoading: false,
				errorMsg: action.message,
			};

		case FETCH_SUBMISSION_ACTION_START:
			return {
				...state,
				isLoading: true,
				modalLoading: false,
				errorMsg: '',
				modalError: '',
				submission: {
					...defaultSubmissionsState.submission,
					submissionid: action.submissionid,
				}
			};

		case FETCH_SUBMISSION_ACTION_OK:
			console.log('FETCH_SUBMISSION_ACTION_OK', { action });
			const col = expandDynamoKeys(action.submission);
			return {
				...state,
				isLoading: false,
				modalLoading: false,
				errorMsg: '',
				modalError: '',
				submission: {
					...state.submission,
					...col,
				}
	  		};

		case FETCH_SUBMISSION_ACTION_ERR:
			return {
				...state,
				isLoading: false,
				modalLoading: false,
				errorMsg: action.message,
				modalError: '',
			};

		case ADD_SUBMISSION_TO_COLLECTION_ACTION_START:
			return {
				...state,
				isLoading: true,
				modalLoading: false,
				errorMsg: '',
				modalError: '',
			};
		case ADD_SUBMISSION_TO_COLLECTION_ACTION_OK:
			console.log('ADD_SUBMISSION_TO_COLLECTION_ACTION_OK FOO', {action, colls: state.submission.collections});
			return { 
				...state,
				isLoading: false,
				submission: {
					...state.submission,
					collections: [ ...state.submission.collections, action.collectionid ],
				}
			}
		case ADD_SUBMISSION_TO_COLLECTION_ACTION_ERR:
			return {
				...state,
				isLoading: false,
				modalLoading: false,
				errorMsg: action.message,
				modalError: '',
			};

			case REMOVE_SUBMISSION_FROM_COLLECTION_ACTION_START:
				return {
					...state,
					isLoading: true,
					modalLoading: false,
					errorMsg: '',
					modalError: '',
				};
			case REMOVE_SUBMISSION_FROM_COLLECTION_ACTION_OK:
				console.log('REMOVE_SUBMISSION_FROM_COLLECTION_ACTION_OK', {action});
				return { 
					...state,
					isLoading: false,
					submission: {
						...state.submission,
						collections: state.submission.collections.map(c => c !== action.collectionid),
					}
				}
			case REMOVE_SUBMISSION_FROM_COLLECTION_ACTION_ERR:
				return {
					...state,
					isLoading: false,
					modalLoading: false,
					errorMsg: action.message,
					modalError: '',
				};

			case ADD_RATING_ACTION_START:
			case REMOVE_RATING_ACTION_START:
				return {
					...state,
					isLoading: true,
					errorMsg: '',
				}

			case ADD_RATING_ACTION_OK:
			case REMOVE_RATING_ACTION_OK:
				return {
					...state,
					isLoading: false,
					submission: {
						...state.submission,
						...action.submission,
					}
				}

			case ADD_RATING_ACTION_ERR:
			case REMOVE_RATING_ACTION_ERR:
				return {
					...state,
					isLoading: false,
					modalLoading: false,
					errorMsg: action.message,
					modalError: '',
				};

			case ADD_SUBMISSION_NOTE_ACTION_START:
				return {
					...state,
					isLoading: true,
					errorMsg: '',
				}

			case ADD_SUBMISSION_NOTE_ACTION_OK: 
				return {
					...state,
					isLoading: false,
					submission: {
						...state.submission,
						notes: [
							action.note,
							...state.submission.notes,
						]
					}
				}

				case DELETE_SUBMISSION_ACTION_START:
					return {
						...state,
						isLoading: true,
						modalLoading: false,
						errorMsg: '',
						modalError: '',
					};
				case DELETE_SUBMISSION_ACTION_OK:
					console.log('DELETE SUBMISSION REDUX ' + action.submissionid);
					return { 
						...state,
						isLoading: false,
						submissions: state.submissions.results.filter(s => s.pk !== action.submissionid)
					}
				case DELETE_SUBMISSION_ACTION_ERR:
					return {
						...state,
						isLoading: false,
						modalLoading: false,
						errorMsg: action.message,
						modalError: '',
					};


					case SAVE_TRANSCRIPTION_ACTION_START:
					case SAVE_TAGS_ACTION_START:
						return {
							...state,
							isLoading: true,
							modalLoading: false,
							errorMsg: '',
							modalError: '',
						};
			
					case SAVE_TRANSCRIPTION_ACTION_OK:
					case SAVE_TAGS_ACTION_OK:
						console.log('SAVE_x_ACTION_OK', { action });
						expanded = expandDynamoKeys(action.submission);
						return {
							...state,
							isLoading: false,
							modalLoading: false,
							errorMsg: '',
							modalError: '',
							submission: {
								...state.submission,
								...expanded,
							}
						  };
			
					case SAVE_TRANSCRIPTION_ACTION_ERR:
					case SAVE_TAGS_ACTION_ERR:
						return {
							...state,
							isLoading: false,
							modalLoading: false,
							errorMsg: action.message,
							modalError: '',
						};					
		default:
			return state;
	}
}
