import { API } from 'aws-amplify';
import { decodeCriteria } from '../shared/constants';

const dayjs = require('dayjs');

export const FETCH_USER_ACTION_START = 'FETCH_USER_ACTION_START';
export const FETCH_USER_ACTION_OK = 'FETCH_USER_ACTION_OK';
export const FETCH_USER_ACTION_ERR = 'FETCH_USER_ACTION_ERR';

const fetchUserStart = (userid) => { return { type: FETCH_USER_ACTION_START, userid}};
const fetchUserOk = (user) => { return { type: FETCH_USER_ACTION_OK, user }};
const fetchUserErr = (error) => { return { type: FETCH_USER_ACTION_ERR, error }};

export function fetchUserAction(userid, successCb, errorCb) {

	console.log('fetchUsersAction START');

	return async (dispatch) => {

		console.log('fetchUserAction: START', userid);
	
		dispatch(fetchUserStart(userid));

		try {
			let endpoint = '/api/admin/user/' + userid;
			console.log('QUERY Querying ... ' + endpoint);
				
			const payload = await API.get('AncestryApi', endpoint);
			console.log('QUERY Found user', { payload });
			dispatch(fetchUserOk(payload.user));
			successCb && successCb();
			return true;
		} catch (error) {
			console.log('QUERY user error:', { error });
			dispatch(fetchUserErr(error.message));
			errorCb && errorCb(error.message);
			return false;
		}

	}
}

export const SAVE_USER_ACTION_START = 'SAVE_USER_ACTION_START';
export const SAVE_USER_ACTION_OK = 'SAVE_USER_ACTION_OK';
export const SAVE_USER_ACTION_ERR = 'SAVE_USER_ACTION_ERR';

const saveUserStart = (userid) => { return { type: SAVE_USER_ACTION_START, userid}};
const saveUserOk = (user) => { return { type: SAVE_USER_ACTION_OK, user }};
const saveUserErr = (error) => { return { type: SAVE_USER_ACTION_ERR, error }};

export function saveUserAction(userid, values, successCb, errorCb) {

	console.log('saveUsersAction START');

	return async (dispatch) => {

		console.log('saveUserAction: START', userid);
	
		dispatch(saveUserStart(userid));

		try {
			let endpoint = '/api/admin/user/' + userid;
			console.log('POST SAVING ... ' + endpoint);
				
			const payload = await API.post('AncestryApi', endpoint, { body: {...values}});
			console.log('POST Found user', { payload });
			dispatch(saveUserOk(payload.user));
			successCb && successCb();
			return true;
		} catch (error) {
			console.log('SAVE user error:', { error });
			dispatch(saveUserErr(error.message));
			errorCb && errorCb(error.message);
			return false;
		}

	}
}

export const CREATE_USER_ACTION_START = 'CREATE_USER_ACTION_START';
export const CREATE_USER_ACTION_OK = 'CREATE_USER_ACTION_OK';
export const CREATE_USER_ACTION_ERR = 'CREATE_USER_ACTION_ERR';

const createUserStart = () => { return { type: CREATE_USER_ACTION_START }};
const createUserOk = (user) => { return { type: CREATE_USER_ACTION_OK, user }};
const createUserErr = (error) => { return { type: CREATE_USER_ACTION_ERR, error }};

export function createUserAction(values, successCb, errorCb) {

	console.log('createUsersAction START');

	return async (dispatch) => {

		console.log('createUserAction: START', values);
	
		dispatch(createUserStart());

		try {
			let endpoint = '/api/admin/user';
			console.log('POST SAVING ... ' + endpoint);
				
			const payload = await API.post('AncestryApi', endpoint, { body: {...values}});
			console.log('POST created user', { payload });
			dispatch(createUserOk(payload.user));
			successCb && successCb();
			return true;
		} catch (error) {
			console.log('CREATE user error:', { error });
			let errorMsg = (error.response && error.response.data && error.response.data.message)
				? error.response.data.message
				: error.message;
			dispatch(createUserErr(errorMsg));
			errorCb && errorCb(errorMsg);
			return false;
		}

	}
}

export const RESET_USER_ACTION_START = 'RESET_USER_ACTION_START';
export const RESET_USER_ACTION_OK = 'RESET_USER_ACTION_OK';
export const RESET_USER_ACTION_ERR = 'RESET_USER_ACTION_ERR';

const resetUserStart = () => { return { type: RESET_USER_ACTION_START }};
const resetUserOk = (user) => { return { type: RESET_USER_ACTION_OK, user }};
const resetUserErr = (error) => { return { type: RESET_USER_ACTION_ERR, error }};

export function resetUserAction(userid, values, successCb, errorCb) {

	console.log('resetUsersAction START');

	return async (dispatch) => {

		console.log('resetUserAction: START', values);
	
		dispatch(resetUserStart());

		try {
			let endpoint = `/api/admin/user/${userid}/reset`;
			console.log('POST SAVING ... ' + endpoint);
				
			const payload = await API.post('AncestryApi', endpoint, { body: {...values}});
			console.log('POST reset user', { payload });
			dispatch(resetUserOk(payload.user));
			successCb && successCb();
			return true;
		} catch (error) {
			console.log('RESET user error:', { error });
			let errorMsg = (error.response && error.response.data && error.response.data.message)
				? error.response.data.message
				: error.message;
			dispatch(resetUserErr(errorMsg));
			errorCb && errorCb(errorMsg);
			return false;
		}

	}
}
